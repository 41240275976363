import { delegateEvent } from 'src/utils/dom.js';

delegateEvent(document, 'click', '[data-header-dropdown-toggle]', (event, target) => {
  const dropdownName = target.dataset.headerDropdownToggle;
  const dropdown = document.querySelector(`[data-header-dropdown="${dropdownName}"]`);
  if (dropdown.dataset.headerDropdownOpen) return;

  dropdown.dataset.headerDropdownOpen = 'open';

  document.addEventListener('click', () => {
    dropdown.removeAttribute('data-header-dropdown-open');
  }, { once: true });
});

document.addEventListener('DOMContentLoaded', () => {
  requestAnimationFrame(() => {
    const activeLink = document.querySelector('[data-scroll-to-active-link] .active');
    if (activeLink) {
      const { scrollY } = window;
      activeLink.scrollIntoView();
      window.scrollTo({
        top: scrollY,
      });
    }
  });
});

if (navigator.userAgent.indexOf('iPhone') > -1 ) {
  const viewport = document.querySelector('[name=viewport]');
  if (!viewport.content.includes('width=1080')) {
    viewport.setAttribute('content','width=device-width, initial-scale=1, maximum-scale=1');
  }
}

