import { delegateEvent } from 'src/utils/dom.js';

function initGaEvents() {
  delegateEvent(document, 'click', '.js-menu-brand', (event, target) => {
    const eventName = target.dataset.event;

    if (!eventName) return;

    window.helpers.trackEventByName(eventName);
  });

  delegateEvent(document, 'click', '.js-open-brand', (event, target) => {
    const eventName = target.dataset.event;
    const eventLabel = target.dataset.label;

    if (!eventName || !eventLabel) return;

    const actualLabel = eventLabel.replace('dod', 'pitch');

    window.helpers.trackEventByName(eventName, {
      event_label: actualLabel,
    });
  });

  delegateEvent(document, 'click', '.js-data-sign-in-btn', () => {
    window.helpers.trackEventByName('enter_button');
  });

  delegateEvent(document, 'click', '.js-data-sign-up-btn', () => {
    window.helpers.trackEventByName('register_button');
  });
}

initGaEvents();
