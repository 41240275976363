import { delegateEvent } from 'src/utils/dom.js';

export const closeOverlay = (target) => {
  target.removeAttribute('data-overlay-open');
};

delegateEvent(document, 'click', '[data-overlay-trigger]', function(event, clicked) {
  event.preventDefault();
  const targetName = clicked.dataset.overlayTrigger;
  const target = document.querySelector(`[data-overlay="${targetName}"]`);
  target.dataset.overlayOpen = 'open';
});

delegateEvent(document, 'click', '[data-overlay]', function(event, clicked) {
  if (['overlay', 'overlayWrapper'].some(attr => attr in clicked.dataset)) {
    const target = clicked.closest('[data-overlay]');
    closeOverlay(target);
  }
});

delegateEvent(document, 'click', '[data-close-overlay]', function(event, clicked) {
  const targetName = clicked.dataset.closeOverlay;
  const target = document.querySelector(`[data-overlay="${targetName}"]`);
  closeOverlay(target);
});

document.addEventListener('keyup', function(event) {
  if (event.key === 'Escape' || event.key === 'Esc') {
    Array.from(document.querySelectorAll('[data-overlay-open]')).forEach(closeOverlay);
  }
});