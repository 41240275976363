import { createPopper } from '@popperjs/core';

import { delegateEvent } from 'src/utils/dom.js';

delegateEvent(document, 'click', '[data-dropdown-trigger]', function(event, target) {
  const targetName = target.dataset.dropdownTrigger;
  const dropdown = document.querySelector(`[data-dropdown-container=${targetName}]`);

  // eslint-disable-next-line no-underscore-dangle
  if (!dropdown.__popper) {
    // eslint-disable-next-line no-underscore-dangle
    dropdown.__popper = createPopper(target, dropdown, {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
    });
  }

  if (dropdown.dataset.dropdownState === 'open') {
    dropdown.dataset.dropdownState = 'closed';
  } else {
    dropdown.dataset.dropdownState = 'open';
  }
});

document.addEventListener('click', function(event) {
  const toExclude = event.target.closest('[data-dropdown-container]') || event.target.closest('[data-dropdown-trigger]');
  const toExcludeName = toExclude && (toExclude.dataset.dropdownContainer || toExclude.dataset.dropdownTrigger);
  const target = toExclude ?
    document.querySelectorAll(`[data-dropdown-state="open"]:not([data-dropdown-container="${toExcludeName}"])`) :
    document.querySelectorAll('[data-dropdown-state="open"]');
  if (target.length === 0) return;
  Array.from(target).forEach((element) => {
    // eslint-disable-next-line no-param-reassign
    element.dataset.dropdownState = 'closed';
  });
});

document.addEventListener('keyup', function(event) {
  if (event.key === 'Escape' || event.key === 'Esc') {
    Array.from(document.querySelectorAll('[data-dropdown-state]')).forEach(el => el.setAttribute('data-dropdown-state', 'closed'));
  }
});