export const delegateEvent = (root, eventName, selector, callback) => {
  root.addEventListener(eventName, (event) => {
    const target = event.target.closest(selector);
    if (!target) return;
    callback(event, target);
  });
};

export const getParentBySelector = (el, selector) => {
  if (!el) return null;

  let parent = el.parentElement;

  while (parent && !parent.matches(selector)) {
    parent = parent.parentElement;
  }

  return parent;
};
